import { styled } from "solid-styled-components";
import { H2 } from "../../../../../components";

export const H2Styled = styled(H2)`
  margin-bottom: 0;
  padding: 16px 24px 24px 24px;
  min-height: 44px;
  font-color: ${(props) => props.theme!.colors.text.contrast};
`;

export const QrCodeWrapper = styled.div`
  margin-top: 24px;
  margin-left: 24px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
