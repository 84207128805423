import {Component, For, JSX, Show} from "solid-js";
import {ButtonsContainerStyled, ContainedPrimaryButton, ContainedPrimaryButtonText} from "./style";
import {Iconnector, OKX_APP_WALLET, OKX_INJECT_WALLET, WalletInfo} from "@okxconnect/core";
import {DesktopConnectionQR} from "../desktop-connection-qr";
import {DesktopOpenInjectedModal} from "../desktop-open-injected-modal";

interface ConnectAppTgPageProps {
    connector: Iconnector[],
    qrLink:()=>string,
    wallet: WalletInfo;
    sessionConnecting:() => boolean
    injectedRetry:()=>void
    injectedOpen:()=>boolean
}

const DesktopConnectAppTgModalPage: Component<ConnectAppTgPageProps> = (props) => {

    const showAppWalletQr = props.connector.some((connector) => connector.getTag() === OKX_APP_WALLET);

    return (
        <>

            {/*<ButtonsContainerStyled>*/}
            {/*    <For each={itemConnector}>*/}
            {/*        {(connector) => (*/}
            {/*            <ContainedPrimaryButton onClick={() => props.walletConnectClick(connector)}>*/}
            {/*                <ContainedPrimaryButtonText translationKey= {connector.walletNameKey}>*/}
            {/*                    {connector.walletName}*/}
            {/*                </ContainedPrimaryButtonText>*/}
            {/*            </ContainedPrimaryButton>*/}
            {/*        )}*/}
            {/*    </For>*/}
            {/*</ButtonsContainerStyled>*/}

            <Show<boolean, () => JSX.Element> when={props.injectedOpen()}>
                <DesktopOpenInjectedModal
                    injectedRetry = {props.injectedRetry}
                />
            </Show>

            <Show<boolean, () => JSX.Element> when={showAppWalletQr}>
                <DesktopConnectionQR
                    universalLink={props.qrLink()}
                    walletInfo={props.wallet}
                    sessionConnecting={props.sessionConnecting}
                />
            </Show>

        </>
    );
};

export default DesktopConnectAppTgModalPage;
