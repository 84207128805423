import { Component } from 'solid-js';
import { ActionModal } from '../action-modal';
import { ErrorIcon } from '../../../../components';
import {isMobile} from '../../../../hooks/isMobile';

interface TransactionErrorModalProps {
    onClose: () => void;
}

export const TransactionErrorModal: Component<TransactionErrorModalProps> = props => {
    return (
        <ActionModal
            headerTranslationKey="actionModal.transactionError.header"
            textTranslationKey="actionModal.transactionCanceled.text"
            headDefaultString='Error'
            icon={<ErrorIcon size="m" />}
            onClose={() => props.onClose()}
            showButton={isMobile() ? 'close' : undefined}
            data-tc-transaction-error-modal="true"
        />
    );
};
