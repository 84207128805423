import type {Component} from 'solid-js';
import {Dynamic} from 'solid-js/web';
import {ThemeProvider} from 'solid-styled-components';
import {createI18nContext, I18nContext} from '@solid-primitives/i18n';
import {i18nDictionary} from "../app/i18n";
import {defineStylesRoot, fixMobileSafariActiveTransition} from "../app/utils/web-api";
import {
  GlobalStyles,
  globalStylesTag,
} from "../app/styles/global-styles";
import {ActionsModal} from "../app/views/modals";
import {universalAppState} from "./config/universal-app.state";
import {UniversalConnectorContext} from "../config/connector.context";
import {OKXConnectUiContext} from "../config/okx-connect-ui.context";
import {OKXUniversalConnectUI} from "./okx-connect-universal-ui";
import {UniversalSingleWalletModal} from "./modals/wallets-modal/single-universal-wallet-modal";
import { universalAction, setUniversalAction } from './config/universal-modals-state';
import { universalThemeState } from './theme/universal-theme-state';

export type UniversalAppProps = {
    connectUI: OKXUniversalConnectUI;
};

export const UniversalApp: Component<UniversalAppProps> = props => {
    const translations = createI18nContext(i18nDictionary, universalAppState.language);

    defineStylesRoot();
    fixMobileSafariActiveTransition();

    // console.log(`UniversalApp UniversalApp UniversalApp  ${JSON.stringify(universalThemeState.colors)}`)

    return (
      <I18nContext.Provider value={translations}>
        <OKXConnectUiContext.Provider value={props.connectUI}>
          <UniversalConnectorContext.Provider
            value={universalAppState.connector!}
          >
            <GlobalStyles />
            <ThemeProvider theme={universalThemeState}>
              <Dynamic
                  component={globalStylesTag}
                  style={{ position: "relative", "z-index": 3001 }}
              >
                <UniversalSingleWalletModal />
                <ActionsModal action={universalAction()} setAction={setUniversalAction} />
              </Dynamic>
            </ThemeProvider>
          </UniversalConnectorContext.Provider>
        </OKXConnectUiContext.Provider>
      </I18nContext.Provider>
    );
};