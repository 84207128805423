import {
  encodeConnectParams,
  encodeTWAConnectURLParams,
  EngineConnectInfo,
  getAppWalletDeepLink, getAppWalletShortDeepLink,
  getOKXLink,
  getTelegramWalletTWAUrl,
  getUniversalLink,
  Iconnector,
  logDebug,
  OKX_APP_WALLET,
  OKX_INJECT_WALLET,
  OKX_TG_WALLET,
  OKXReportType,
  Report_ChooseType_ConnectWallet_Click,
  Report_ChooseType_ConnectWallet_View,
  Report_Loading_ConnectWallet_View,
  Report_Proceed_Proceed_View,
  Report_Proceed_QRCode_Click,
  Report_Proceed_QRCode_View,
  Report_Proceed_Retry_Click,
  reportEvent,
  useShortLink,
  WalletInfo
} from "@okxconnect/core";
import {Component, createEffect, createMemo, createSignal, JSX, onMount, Show, useContext,} from "solid-js";
import {BodyStyled, MobileConnectionModalStyled, StyledIconButton,} from "./style";
import {useTheme} from "solid-styled-components";
import {MobileConnectionQR} from "./mobile-connection-qr";
import LoadingModalPage from "../layout/LoadingModalPage";
import ModalFooter from "../layout/ModalFooter";
import {H1Styled} from "../style";
import ConnectionDeclinedModalPage from "../layout/ConnectionDeclinedModalPage";
import ContinueInWalletModalPage from "../layout/ContinueInWalletModalPage";
import {OKXConnectUiContext} from "../../../../../config/okx-connect-ui.context";
import {WalletInfoRemoteWithOpenMethod, WalletInfoWithOpenMethod, WalletOpenMethod} from "../../../../../models";
import MobileConnectAppTgModalPage from "./mobile-wallet-select-modal";
import {isMobile} from "../../../../hooks/isMobile";


export interface MobileConnectionProps {
  wallet: WalletInfo;
  onBackClick: () => void;
  backDisabled?: boolean;
  connect: () => Promise<unknown>;
  universalLinkParams: () => EngineConnectInfo | null;
  showRetry:boolean;
  connectError:() => boolean
  sessionConnecting:() => boolean
  walletConnector:Iconnector[]
  lastSelectedWalletInfo: WalletInfoRemoteWithOpenMethod | {
    openMethod: WalletOpenMethod;
  } | null;
  setLastSelectedWalletInfo(walletInfo: WalletInfoWithOpenMethod | {
    openMethod: WalletOpenMethod;
} | null): void;
}

export const MobileConnectionModal: Component<MobileConnectionProps> = (
  props
) => {
  const theme = useTheme();
  const [showQR, setShowQR] = createSignal(false);
  const [connectClicked, setConnectClicked] = createSignal(false);

  const showAppAndTgState = createMemo(
    () => (props.walletConnector.length > 1 ||
        (props.walletConnector.length == 1 && props.walletConnector[0]!.getTag() !== OKX_APP_WALLET)) && !connectClicked()
  );

  const [qrLink, setQrLink] = createSignal<string | null>(null);
  const okxConnectUI = useContext(OKXConnectUiContext)
  var lastActionBtnShowState: boolean | undefined;
  var lastQRViewShowState: boolean | undefined;


  let showLoading = () => props.universalLinkParams() === null && !props.connectError();
  var lastLoadingState: boolean | undefined;

  createEffect(() => {
    var info = props.universalLinkParams();
    const showState = showAppAndTgState();
    const showQRState = showQR();
    const showLoadingState = showLoading();
    if (showState != lastActionBtnShowState && !showQRState && !showLoadingState) {
      if (showState) {
        // report show
        reportEvent(OKXReportType.PAGE_IN, Report_Proceed_Proceed_View, '')
        reportEvent(OKXReportType.PAGE_OUT, Report_ChooseType_ConnectWallet_View, '')
      } else {
        // report hidden
        reportEvent(OKXReportType.PAGE_OUT, Report_Proceed_Proceed_View, '')
        reportEvent(OKXReportType.PAGE_IN, Report_ChooseType_ConnectWallet_View, '')
      }
      lastActionBtnShowState = showState;
    }

    if (showQRState != lastQRViewShowState && !showLoadingState) {
      if (showQRState) {
        // report show
        reportEvent(OKXReportType.PAGE_IN, Report_Proceed_QRCode_View, '')
      } else {
        // report hidden
        reportEvent(OKXReportType.PAGE_OUT, Report_Proceed_QRCode_View, '')
      }
      lastQRViewShowState = showQRState;
    }

    if (showLoadingState != lastLoadingState) {
      if (showLoadingState) {
        // report show
        reportEvent(OKXReportType.PAGE_IN, Report_Loading_ConnectWallet_View, '')
      } else {
        // report hidden
        reportEvent(OKXReportType.PAGE_OUT, Report_Loading_ConnectWallet_View, '')
      }
      lastLoadingState = showLoadingState;
    }

    if (info) {
      props.setLastSelectedWalletInfo({
        ...props.wallet,
        openMethod: "universal-link",
      });

      logDebug(`createEffect  ${JSON.stringify(info)}`)
      var connectRequest = info.connectRequest

      if (connectRequest) {
        var shortUniversalLink = getAppWalletShortDeepLink(connectRequest.topic, connectRequest.clientId)
        setQrLink(shortUniversalLink);
      }
    }

    if (props.connectError()){
      setShowQR(false)
    }
  });

  onMount(async () => {
    try {
      await props.connect().catch((error) => 
        logDebug(`MobileConnectionModal onMount error ${JSON.stringify(error)}`)
      )
    } catch (error) {
      console.error("Error while connecting:", error);
    }
  });

  const onRetry = (): void => {
    setConnectClicked(false);
    reportEvent(OKXReportType.CLICK, Report_Proceed_Retry_Click, '');

    try {
      props.connect();
    } catch (error) {
      console.error("Error while connecting:", error);
    }
  };

  const onOpenQR = (): void => {
    reportEvent(OKXReportType.CLICK, Report_Proceed_QRCode_Click, '');
    setShowQR(true);
    props.setLastSelectedWalletInfo({
      ...props.wallet,
      openMethod: "qrcode",
    });
  };

  const onCloseQR = (): void => {
    setShowQR(false);
    props.setLastSelectedWalletInfo({
      ...props.wallet,
      openMethod: "universal-link",
    });
  };

  const onBack = (): void => {
    if (showQR()) {
      onCloseQR();
    } else {
      props.onBackClick();
    }
  };

  const walletConnectClick = (connector:Iconnector)=>{
    var info = props.universalLinkParams();
     switch (connector.getTag()){
       case OKX_APP_WALLET:{
         reportEvent(OKXReportType.CLICK, Report_ChooseType_ConnectWallet_Click, JSON.stringify({Report_WalletType_Key: 'app'}));
         if (isMobile()){
           setConnectClicked(true);
           if (info){
             connector.openWallet(info)
           }
         }else{
           onOpenQR()
         }
         return
       }
       case OKX_TG_WALLET:{
         reportEvent(OKXReportType.CLICK, Report_ChooseType_ConnectWallet_Click, JSON.stringify({Report_WalletType_Key: 'tgminiwallet'}));
         okxConnectUI?.closeModal()
         if (info){
           connector.openWallet(info)
         }
         break
       }
       case OKX_INJECT_WALLET:{
         okxConnectUI?.closeModal()
         if (info){
           connector.openWallet(info)
         }
         break
       }
     }
  }

  // {/*<MobileConnectionQR universalLink={universalLink()} walletInfo={props.wallet} />*/}

  const [showLoadingModalPage, setShowLoadingModalPage] = createSignal(false);
  createEffect(()=>{

      }
  )

  return (
    <MobileConnectionModalStyled data-tc-wallets-modal-connection-mobile="true">
      <Show<boolean, () => JSX.Element> when={!props.backDisabled}>
        <StyledIconButton icon="arrow" onClick={onBack} />
      </Show>

      <Show<boolean, () => JSX.Element> when={props.universalLinkParams() === null && !props.connectError()}>
        <H1Styled>OKX Connect</H1Styled>
        <LoadingModalPage />
        <ModalFooter wallet={props.wallet}/>
      </Show>


      <Show<boolean, () => JSX.Element> when={props.universalLinkParams() !== null || props.connectError()}>
        <Show<boolean, () => JSX.Element> when={showQR() && !props.connectError()}>
            <MobileConnectionQR
                universalLink={qrLink()}
                walletInfo={props.wallet}
                sessionConnecting = {props.sessionConnecting}
            />
        </Show>

        <Show<boolean, () => JSX.Element> when={!showQR()}>
          <H1Styled>OKX Connect</H1Styled>
          <BodyStyled isDeclined={props.connectError()}>
            <Show<boolean, () => JSX.Element> when={props.connectError()}>
              <ConnectionDeclinedModalPage
                  onRetry={onRetry}
                  showRetry = {props.showRetry}
              />
            </Show>

            <Show<boolean, () => JSX.Element> when={!props.connectError()}>
              <Show<boolean, () => JSX.Element> when={showAppAndTgState()}>
                <MobileConnectAppTgModalPage
                  connector={props.walletConnector}
                  walletConnectClick={walletConnectClick}
                />
              </Show>

              <Show<boolean, () => JSX.Element> when={!showAppAndTgState()}>
                <ContinueInWalletModalPage
                  onOpenQR={onOpenQR}
                  onRetry={onRetry}
                />
              </Show>
            </Show>
          </BodyStyled>

          <ModalFooter wallet={props.wallet}/>
        </Show>
      </Show>
    </MobileConnectionModalStyled>
  );
};
