import { createStore } from 'solid-js/store';
import { Locales } from '../../models';
import { Loadable } from '../../models';

import { OKXITonConnect, ConnectAdditionalRequest } from '@okxconnect/tonsdk';
import {Iconnector, RequestMetadata} from "@okxconnect/core";

export type TonAppState = {
    connector: OKXITonConnect | null;
    walletConnector:Iconnector[]
    buttonRootId: string | null;
    language: Locales;
    connectRequestParameters?: Loadable<ConnectAdditionalRequest> | null;
    preferredWalletAppName: string | undefined;
    dappMetaData:RequestMetadata | undefined
};

export const [tonAppState, setTonAppState] = createStore<TonAppState>({
    buttonRootId: null,
    language: 'en_US'
} as TonAppState);
