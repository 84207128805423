import {Component, createEffect, createSignal, Show} from 'solid-js';
import {
    ImageBackground,
    ImageStyled,
    imgSizeDefault,
    picSizeDefault,
    QrCodeBackground,
    QrCodeWrapper,
    qrNormalSize
} from './style';
import qrcode from 'qrcode-generator';
import {Transition} from 'solid-transition-group';
import {Styleable} from '../../models/styleable';
import {toPx} from '../../utils/css';
import {animate} from '../../utils/animate';
import {useTheme} from "solid-styled-components";
import {THEME} from "../../../models";

export interface QRCodeProps extends Styleable {
    sourceUrl: string;
    imageUrl?: string;
    disableCopy?: boolean;
}

export const QRCode: Component<QRCodeProps> = props => {
    let qrCodeCanvasRef: HTMLDivElement | undefined;
    let qrCodeWrapperRef: HTMLDivElement | undefined;
    let imageRef: HTMLDivElement | undefined;

    const [copyButtonOpened, setCopyButtonOpened] = createSignal(false);

    const [picSize, setPicSize] = createSignal(picSizeDefault);

    createEffect(() => {
        const errorCorrectionLevel = 'M'; // 调整纠错级别
        const cellSize = 5; // 调整单元格大小
        const qr = qrcode(0, errorCorrectionLevel);
        qr.addData(props.sourceUrl);
        qr.make();

        if (qrCodeCanvasRef) {
            // 获取模块数量
            const moduleCount = qr.getModuleCount();
            const size = moduleCount * cellSize;

            // 根据主题设置颜色
            const isDarkMode = useTheme().theme == THEME.DARK; // 或其他判断暗色模式的方法
            const primaryColor = isDarkMode ? 'white' : 'black';
            const secondaryColor = isDarkMode ? 'black' : 'white';

            // 判断是否是定位点
            const isPositioningPoint = (row: number, col: number): boolean => {
                // 左上角
                if (row < 7 && col < 7) return true;
                // 右上角
                if (row < 7 && col >= moduleCount - 7) return true;
                // 左下角
                if (row >= moduleCount - 7 && col < 7) return true;
                return false;
            };

            // 先计算缩放比例
            const qrSize = moduleCount * cellSize;
            const scale = Math.round((qrNormalSize / qrSize) * 100000) / 100000;

            // 计算实际的图片尺寸（以 cellSize 为单位）
            const calculateImageCells = (scale: number) => {
                const sizeCells = Math.ceil(imgSizeDefault / (scale * cellSize));
                const offsetCells = Math.ceil((moduleCount - sizeCells) / 2);
                return {
                    sizeCells,
                    offsetCells
                };
            };
            // 修改判断中心图片区域的函数
            const isNearCenterImage = (row: number, col: number): boolean => {
                const { sizeCells } = calculateImageCells(scale);
                const center = Math.floor(moduleCount / 2);

                // 增加清除区域的大小，确保完全覆盖
                const clearSize = sizeCells + 3; // 增加边距
                const radius = clearSize / 2;

                // 计算到中心的距离
                const distance = Math.sqrt(
                    Math.pow(row - center, 2) +
                    Math.pow(col - center, 2)
                );

                // 使用更精确的浮点数比较，不进行向下取整
                return distance <= radius;
            };

            let svg = `<svg width="${size}" height="${size}" viewBox="0 0 ${size} ${size}" fill="none" xmlns="http://www.w3.org/2000/svg">`;

            // 绘制定位点
            const drawPositioningPattern = (startRow: number, startCol: number) => {
                // 1. 绘制白色外框
                const patternSize = 7 * cellSize; // 定位点总大小
                const radius = cellSize * 0.8; // 圆角半径
                // 1. 绘制白色外框
                // 上边（左上和右上圆角）
                svg += `<rect 
            x="${startCol * cellSize}" 
            y="${startRow * cellSize}" 
            width="${patternSize}" 
            height="${cellSize}" 
            fill="${primaryColor}"
            rx="${radius}"
        />`;
                // 下边（左下和右下圆角）
                svg += `<rect 
            x="${startCol * cellSize}" 
            y="${(startRow + 6) * cellSize}" 
            width="${patternSize}" 
            height="${cellSize}" 
            fill="${primaryColor}"
            rx="${radius}"
        />`;
                // 左边（左上和左下圆角）
                svg += `<rect 
            x="${startCol * cellSize}" 
            y="${startRow * cellSize}" 
            width="${cellSize}" 
            height="${patternSize}" 
            fill="${primaryColor}"
            rx="${radius}"
        />`;
                // 右边（右上和右下圆角）
                svg += `<rect 
            x="${(startCol + 6) * cellSize}" 
            y="${startRow * cellSize}" 
            width="${cellSize}" 
            height="${patternSize}" 
            fill="${primaryColor}"
            rx="${radius}"
        />`;

                // 第三层白色方框
                svg += `<rect 
            x="${(startCol + 2) * cellSize}" 
            y="${(startRow + 2) * cellSize}" 
            width="${cellSize * 3}" 
            height="${cellSize * 3}" 
            rx="${radius}"
            fill="${primaryColor}"
        />`;
            };

            // 先绘制定位点
            drawPositioningPattern(0, 0); // 左上
            drawPositioningPattern(0, moduleCount - 7); // 右上
            drawPositioningPattern(moduleCount - 7, 0); // 左下

            // 优化数据点的绘制样式
            const drawDataPoint = (x: number, y: number) => {
                const dotSize = cellSize * 0.85; // 稍微减小点的大小
                const offset = (cellSize - dotSize) / 2;
                const radius = dotSize / 3; // 调整圆角

                return `<rect 
        x="${x + offset}" 
        y="${y + offset}" 
        width="${dotSize}" 
        height="${dotSize}" 
        rx="${radius}"
        fill="${primaryColor}"
    />`;
            };

            // 在数据点绘制循环中使用
            for (let row = 0; row < moduleCount; row++) {
                for (let col = 0; col < moduleCount; col++) {
                    if (qr.isDark(row, col) &&
                        !isPositioningPoint(row, col) &&
                        !isNearCenterImage(row, col)) {

                        svg += drawDataPoint(col * cellSize, row * cellSize);
                    }
                }
            }

            svg += '</svg>';
            qrCodeCanvasRef.innerHTML = svg;

            // 在设置图片样式时转换为像素
            if (imageRef) {
                const { sizeCells, offsetCells } = calculateImageCells(scale);
                const imgSize = sizeCells * cellSize;
                const imgOffset = offsetCells * cellSize;

                imageRef.style.top = toPx(imgOffset);
                imageRef.style.left = toPx(imgOffset);
                imageRef.style.height = toPx(imgSize);
                imageRef.style.width = toPx(imgSize);
                imageRef.style.borderRadius = toPx(1000000);

                setPicSize(Math.round(picSizeDefault / scale));
            }

            if (qrCodeWrapperRef) {
                qrCodeWrapperRef.style.transform = `scale(${scale})`;
            }
        }
    });

    return (
        <QrCodeBackground class={props.class} >
            <QrCodeWrapper ref={qrCodeWrapperRef}>
                <div ref={qrCodeCanvasRef} />
                <Show when={props.imageUrl}>
                    <ImageBackground ref={imageRef}>
                        <ImageStyled src={props.imageUrl!} alt="" size={picSize()} />
                    </ImageBackground>
                </Show>
            </QrCodeWrapper>
            <Transition
                onBeforeEnter={el => {
                    animate(
                        el,
                        [
                            { opacity: 0, transform: 'translate(-50%, 44px)' },
                            { opacity: 1, transform: 'translate(-50%, 0)' }
                        ],
                        {
                            duration: 150,
                            easing: 'ease-out'
                        }
                    );
                }}
                onExit={(el, done) => {
                    animate(
                        el,
                        [
                            { opacity: 1, transform: 'translate(-50%, 0)' },
                            { opacity: 0, transform: 'translate(-50%, 44px)' }
                        ],
                        {
                            duration: 150,
                            easing: 'ease-out'
                        }
                    ).finished.then(() => {
                        done();
                    });
                }}
            >
            </Transition>
        </QrCodeBackground>
    );
};
