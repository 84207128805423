import {styled} from "solid-styled-components";
import {Button, H1, H3, Image, Text} from "../../../../../components";
import {rgba} from "../../../../../utils/css";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-top: 10px;
  border-bottom: 0.5px solid ${(props) => rgba(props.theme!.colors.icon.secondary, 0.2)};
  width: 100%;
`;

export const TextContainer = styled.div`
  flex: 1;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`

export const TextMainStyled = styled(H1)`
  font-weight: 500;
  font-size: 18px;
  text-align: left;
`;

export const TextSecondStyled = styled(Text)`
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
  color: ${(props) => props.theme!.colors.text.contrast};
`;

export const ImageStyled = styled(Image)`
  width: 48px;
  height: 48px;
  margin-left: 24px;
`;

export const Retry = styled(Button)`
  border-radius: 20px;
  padding: 6px 14px;
  margin-right: 24px;
  display: inline-flex;
  background-color: transparent;
  border: 0.5px solid ${(props) => props.theme!.colors.text.secondary};;
  white-space: nowrap;
  cursor: pointer;
`

export const H1StyledRetry = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) => props.theme!.colors.text.primary};
`;