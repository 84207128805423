import {Component} from "solid-js";
import {Container, H1StyledRetry, ImageStyled, Retry, TextContainer, TextMainStyled, TextSecondStyled} from "./style";
import imageUrl from "../../../../../assets/okx-icon-corner.png";

interface OpenInjectedProps {
    injectedRetry: () => void
}

export const DesktopOpenInjectedModal: Component<OpenInjectedProps> = (props) => {

    return (
        <Container>

            <ImageStyled src={imageUrl}/>

            <TextContainer>
                <TextMainStyled translationKey="walletModal.mobileConnectionModal.connectInjectedOpening">
                    Opening OKX Wallet...
                </TextMainStyled>

                <TextSecondStyled translationKey="walletModal.mobileConnectionModal.connectInjectedConfirm">
                    Confirm in OKX Wallet extension
                </TextSecondStyled>
            </TextContainer>

            <Retry onClick={props.injectedRetry}>
                <H1StyledRetry translationKey="common.retry">Retry</H1StyledRetry>
            </Retry>
        </Container>

    );

};

