import {Component, createEffect, createMemo, JSX, Show} from "solid-js";
import { H1Styled, StyledModal } from "./style";
import { useI18n } from "@solid-primitives/i18n";
import { isMobile, updateIsMobile } from "../../../hooks/isMobile";
import {
  MobileConnectionModal,
} from "./mobile-connection-modal";
import { Dynamic } from "solid-js/web";
import {
  DesktopConnectionModal,
} from "./desktop-connection-modal";
import { Locales, WalletInfoRemoteWithOpenMethod, WalletInfoWithOpenMethod, WalletOpenMethod, WalletsModalCloseReason } from "../../../../models";
import LoadingModalPage from "./layout/LoadingModalPage";
import {EngineConnectInfo, Iconnector, WalletInfoRemote} from "@okxconnect/core";
import {inTg} from "../../../utils/wallets";

export interface SingleWalletModalProps {
  opened: boolean;
  walletInfo: WalletInfoRemote | null;
  loadingBeforeConnect: () => boolean;
  universalLinkParams: () => EngineConnectInfo | null
  connect: () => Promise<unknown>;
  onClose: (closeReason: WalletsModalCloseReason) => void;
  showRetry:boolean;
  connectError:() => boolean
  walletConnector: () => Iconnector[]
  sessionConnecting:() => boolean
  lastSelectedWalletInfo: WalletInfoRemoteWithOpenMethod | {
    openMethod: WalletOpenMethod;
  } | null;
  setLastSelectedWalletInfo(walletInfo: WalletInfoWithOpenMethod | {
    openMethod: WalletOpenMethod;
} | null): void;
  language: Locales;
}

export const SingleWalletModal: Component<SingleWalletModalProps> = (props) => {
  const { locale } = useI18n()[1];
  createEffect(() => locale(props.language));

  const showMobileModal = createMemo(()=> isMobile() || inTg())

  createEffect(() => {
    if (props.opened) {
      updateIsMobile();
    }
  });

  return (
    <StyledModal
      opened={props.opened}
      onClose={() => props.onClose("action-cancelled")}
      data-tc-wallets-modal-container="true"
    >
      <Show when={props.loadingBeforeConnect()}>
        <H1Styled>{props.walletInfo?.name}</H1Styled>
        <LoadingModalPage />
      </Show>

      <Show<boolean, () => JSX.Element> when={!props.loadingBeforeConnect()}>
        <Dynamic
            component={
              showMobileModal() ? MobileConnectionModal : DesktopConnectionModal
            }
          wallet={props.walletInfo!} // TODO: remove non-null assertion
          onBackClick={() => {}}
          backDisabled={true}
          connect={props.connect}
          universalLinkParams = {props.universalLinkParams}
          lastSelectedWalletInfo={props.lastSelectedWalletInfo}
          setLastSelectedWalletInfo={props.setLastSelectedWalletInfo}
          connectError ={props.connectError}
          showRetry ={props.showRetry}
          sessionConnecting = {props.sessionConnecting}
          walletConnector={props.walletConnector()}
        />
      </Show>
    </StyledModal>
  );
};
