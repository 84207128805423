import { styled } from "solid-styled-components";
import { H1, Modal } from "../../../components";
import { media } from "../../../styles/media";

export const StyledModal = styled(Modal)`
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  ${media("mobile")} {
    padding-left: 0;
    padding-right: 0;
    min-height: 364px;
  }
`;

export const H1Styled = styled(H1)`
  padding: 12px 24px;
  text-align: left;
`;
