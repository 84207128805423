import {
    BridgeConnectionRequest,
    ConnectMethodResponseItem,
    EngineConnectInfo,
    Iconnector,
    ConnectMethodResponseSuccessItem,
    creatOKXMiniAppWalletInfo,
    creatOKXWalletInfo,
    EngineTypes,
    logDebug,
    OKX_CONNECT_ERROR_CODES,
    OKXConnectError as CoreOKXConnectError,
    RequestArguments,
    RequestParams,
    SessionTypes,
    WalletInfo, reportEvent, OKXReportType,Report_ConnectDapp_ConnectAndSign_Click,creatOKXInjectWalletInfo
} from "@okxconnect/core";
import {universalWidgetController as widgetController} from './config/universal-widget-controller';
import {getSystemTheme, subscribeToThemeChange} from '../app/utils/web-api';
import {mergeOptions} from '../app/utils/options';
import {setUniversalAppState} from './config/universal-app.state';
import {unwrap} from 'solid-js/store';
import {ActionConfiguration, StrictActionConfiguration,} from '../models/action-configuration';
import {OKXConnectUiError} from '../errors';
import {OKXConnectUiOptions, WalletsModalCloseReason, WalletsModalState,} from '../models';
import {
    connectMethodsAdaptOldVersion,
    creatNotSupportMethodItemReplay,
    IUniversalForProvider,
    OKXConnectError as UniversalProviderOKXConnectError,
    OKXUniversalProvider
} from "@okxconnect/universal-provider";
import {OKXConnectUI} from "../okx-connect-ui.interface";
import {UniversalSingleWalletModalManager} from "./modals/UniversalSingleWalletModalManager";
import {OKXUniversalConnectUiCreateOptions} from "./okx-connect-universal-ui-create-options";
import {
    eqWalletName, getDefaultConnector,
    isAppWallet, isInjectWallet, isTgWallet,
    openUniversalWallet,
    openWalletForUIRequest, showActionButton
} from "../app/utils/wallets";
import {isMobile} from "../app/hooks/isMobile";
import {isDevice} from "../app/styles/media";
import {isValidLocale} from "../models/locales";
import {isInTMA} from "../app/utils/tma-api";
import bs58 from "bs58";
import _ from 'lodash';

import { SingleWalletModalState } from "../models/single-wallet-modal";
import { lastUniversalSelectedWalletInfo } from "./config/universal-modals-state";
import { setUniversalTheme } from "./theme/universal-theme-state";

export class OKXUniversalConnectUI implements OKXConnectUI,IUniversalForProvider {
    public static getWallets(): WalletInfo[] {
        return [creatOKXWalletInfo(),creatOKXMiniAppWalletInfo(),creatOKXInjectWalletInfo()];
    }

    private readonly singleWalletModal: UniversalSingleWalletModalManager;
    private actionsConfiguration?: ActionConfiguration;
    private systemThemeChangeUnsubscribe: (() => void) | null = null;
    private universalProvider!: OKXUniversalProvider;
    private static initPromise: Promise<OKXUniversalConnectUI> | null = null;

    public set uiOptions(options: OKXConnectUiOptions) {
        logDebug('OKXUniversalConnectUI uiOptions() called')
        this.actionsConfiguration = options.actionsConfiguration;
        if (options.uiPreferences?.theme) {
            if (options.uiPreferences?.theme !== 'SYSTEM') {
                this.systemThemeChangeUnsubscribe?.();
                // setUniversalTheme(options.uiPreferences.theme, options.uiPreferences.colorsSet);
                setUniversalTheme(options.uiPreferences.theme);
            } else {
                // setUniversalTheme(getSystemTheme(), options.uiPreferences.colorsSet);
                setUniversalTheme(getSystemTheme());

                if (!this.systemThemeChangeUnsubscribe) {
                    this.systemThemeChangeUnsubscribe = subscribeToThemeChange(setUniversalTheme);
                }
            }
        } else {
            // if (options.uiPreferences?.colorsSet) {
            //     setColors(options.uiPreferences.colorsSet);
            // }
        }

        if (options.language && !isValidLocale(options.language.toString())){
            options.language = "en_US"
        }

        setUniversalAppState(state => {
            const merged = mergeOptions(
                {
                    ...(options.language && { language: options.language }),
                    ...(!!options.actionsConfiguration?.returnStrategy && {
                        returnStrategy: options.actionsConfiguration.returnStrategy
                    }),
                },
                unwrap(state)
            );
            return merged;
        });
    }

    public async request<T = unknown>(
        args: Omit<RequestArguments, 'redirect'>,
        chain?: string | undefined,
        actionConfiguration?: ActionConfiguration,
    ): Promise<T> {
        logDebug('OKXUniversalConnectUI request() called')
        if (!this.session) {
            throw new OKXConnectUiError('Connect wallet to send a transaction.');
        }
        const options: ActionConfiguration = {
          returnStrategy: (
            isDevice("mobile") &&
            lastUniversalSelectedWalletInfo()?.openMethod !== 'qrcode'
        ) ? actionConfiguration?.returnStrategy : "none", //redirect
            modals: actionConfiguration?.modals,
          tmaReturnUrl: actionConfiguration?.tmaReturnUrl ?? "back",
        };
        const { modals, returnStrategy, tmaReturnUrl } =
          this.getModalsAndNotificationsConfiguration(options);
        const reqArgs: RequestArguments = { ...args, redirect: returnStrategy };
        const showRequestModal = this.universalProvider.showRequestModal(reqArgs, chain);

        if (!isInjectWallet(this.getConnectWallet())) {
            widgetController.setAction({
                name: 'confirm-transaction',
                openModal: modals.includes('before') && showRequestModal,
                sent: false
            });
        }

        if (
            !this.session.sessionConfig?.openUniversalUrl &&
            showRequestModal && 
            openWalletForUIRequest(this.getConnectWallet(), lastUniversalSelectedWalletInfo()?.openMethod)
        ){
          openUniversalWallet(
            this.getConnectWallet(),
            tmaReturnUrl,
            this.session.namespaces
          );
        }

        if (this.session.sessionConfig) {
            this.session.sessionConfig.openUniversalUrl = this.session.sessionConfig.openUniversalUrl && isMobile() && lastUniversalSelectedWalletInfo()?.openMethod !== 'qrcode';
        }
        try {
            const result = await this.universalProvider.request<T>(reqArgs,chain);
            widgetController.setAction({
                name: 'transaction-sent',
                openModal: modals.includes('success') && showRequestModal
            });
            return result;
        } catch (e) {
            logDebug(`OKXUniversalConnectUI  request  ==>  ${typeof e} ==> is core OKXConnectError: ${e instanceof CoreOKXConnectError} ==> is universal-provider OKXConnectError: ${e instanceof UniversalProviderOKXConnectError} ==>  ${JSON.stringify(e)}`)


            if (e instanceof CoreOKXConnectError || e instanceof UniversalProviderOKXConnectError) {
                logDebug(`OKXUniversalConnectUI  request  ==> ${JSON.stringify(e)}`)

                if (e.code === OKX_CONNECT_ERROR_CODES.USER_REJECTS_ERROR){
                    // widgetController.setAction({
                    //     name: 'transaction-error',
                    //     openModal: modals.includes('error') && showRequestModal
                    // });
                    widgetController.setAction({
                        name: 'transaction-canceled',
                        openModal: modals.includes('error') && showRequestModal
                    });
                }else{
                    widgetController.setAction({
                        name: 'transaction-error',
                        openModal: modals.includes('error') && showRequestModal
                    });
                }

                throw e;
            } else {
                widgetController.setAction({
                    name: 'transaction-error',
                    openModal: modals.includes('error') && showRequestModal
                });
                throw new OKXConnectUiError('Unhandled error:' + e);
            }
        }
    }

    private getModalsAndNotificationsConfiguration(
        options?: ActionConfiguration
    ): StrictActionConfiguration {
        const allActions: StrictActionConfiguration['modals'] = [
            'before',
            'success',
            'error'
        ];

        let modals: StrictActionConfiguration['modals'] = ['before'];
        if (this.actionsConfiguration?.modals) {
            if (this.actionsConfiguration.modals === 'all') {
                modals = allActions;
            } else {
                modals = this.actionsConfiguration.modals;
            }
        }
        if (options?.modals) {
            if (options.modals === 'all') {
                modals = allActions;
            } else {
                modals = options.modals;
            }
        }

        const returnStrategy = options?.returnStrategy || this.actionsConfiguration?.returnStrategy || 'back';
        const tmaReturnUrl =
            options?.tmaReturnUrl || this.actionsConfiguration?.tmaReturnUrl || 'back';

        return {
            modals,
            tmaReturnUrl,
            returnStrategy,
        };
    }

    public static async init(opts: OKXUniversalConnectUiCreateOptions):Promise<OKXUniversalConnectUI> {
        logDebug('OKXUniversalConnectUI init() called')
        if (window && (window as any).okxConnectUniversalUIinwindow instanceof OKXUniversalConnectUI) {
            logDebug('OKXUniversalConnectUI init() old called');
            return (window as any).okxConnectUniversalUIinwindow;
        }
        if (this.initPromise) {
            logDebug('OKXUniversalConnectUI init() is waiting for ongoing initialization');
            return this.initPromise;
        }
        this.initPromise = (async () => {
            if (!opts || !opts.dappMetaData) {
                throw new OKXConnectUiError('You have to specify a `dappMetaData` in the options.');
            }
            logDebug('OKXUniversalConnectUI init() new called');
            if (!opts.connector || opts.connector.length === 0) {
                opts.connector = getDefaultConnector();
            }
            const universalProvider = await OKXUniversalProvider.init({
                dappMetaData: opts.dappMetaData,
                connector: opts.connector
            });
            const provider = new OKXUniversalConnectUI(opts, universalProvider);
            if (window) {
                (window as any).okxConnectUniversalUIinwindow = provider;
            }
            return provider;
        })();
        try {
            const provider = await this.initPromise;
            return provider;
        } finally {
            this.initPromise = null;
        }
    }

    private constructor(options: OKXUniversalConnectUiCreateOptions,universalProvider:OKXUniversalProvider) {
        logDebug("OKXUniversalConnectUI constructor options:",JSON.stringify(options))
        this.singleWalletModal = new UniversalSingleWalletModalManager({
            getWalltsInfo: () => OKXUniversalConnectUI.getWallets()
        });
        const rootId = this.normalizeWidgetRoot(undefined);
        this.universalProvider = universalProvider
        this.uiOptions = mergeOptions(options, {uiPreferences: {theme: 'SYSTEM'}, dappMetaData: options.dappMetaData});
        setUniversalAppState({ connector: universalProvider,walletConnector:options.connector,dappMetaData:options.dappMetaData });
        widgetController.renderUniversalApp(rootId, this);
    }



    private normalizeWidgetRoot(rootId: string | undefined): string {
        if (!rootId || !document.getElementById(rootId)) {
            rootId = `universal-widget-root`;
            const rootElement = document.createElement('div');
            rootElement.id = rootId;
            document.body.appendChild(rootElement);
        }

        return rootId;
    }


    public getWallets(): WalletInfo[] {
        return OKXUniversalConnectUI.getWallets();
    }

    public async handleConnect(connectMethod: (actionConfiguration:ActionConfiguration | undefined) => Promise<SessionTypes.Struct | undefined>):Promise<SessionTypes.Struct | undefined> {
      return  this.singleWalletModal.handleConnect(connectMethod,this.actionsConfiguration);
    };

    private async checkConnectMethods(connectParams?: RequestParams[],responseItems?:ConnectMethodResponseItem[]){
        logDebug("checkConnectMethods connectParams111 >>>>",JSON.stringify(connectParams))
        logDebug("checkConnectMethods responseItems>>>>",JSON.stringify(responseItems))

        if (this.universalProvider.session && connectParams && connectParams.length>0){
            if (!responseItems || responseItems.length === 0){ // adapt old version
                var requestItem = connectParams[0] as RequestParams
                if (requestItem){
                    if (requestItem.method === "sync_all_addresses"){
                        return
                    }
                    let list:ConnectMethodResponseItem[] = []
                    try {
                        let callBackParams = await connectMethodsAdaptOldVersion(requestItem,this.universalProvider.session)
                        logDebug("checkConnectMethods callBackParams111>>>>",JSON.stringify(callBackParams))

                        let config = {} as ActionConfiguration
                        if (isInTMA()){
                            config["returnStrategy"] = "tg://resolve"
                        }
                        logDebug("checkConnectMethods requestItem>>>>",JSON.stringify(requestItem))
                        logDebug("checkConnectMethods callBackParams222>>>>",JSON.stringify(callBackParams))

                        this.request(requestItem,requestItem.chainId,config).then(requestResult =>{
                            let resultItem = requestResult as ConnectMethodResponseSuccessItem
                            logDebug(`checkConnectMethods then resultItem ====> ${JSON.stringify(resultItem)}`);
                            //     "",
                            if (requestItem.method === "wallet_addEthereumChain"){
                                resultItem = {
                                    method: requestItem.method,
                                    chainId: requestItem.chainId,
                                    result: ""
                                } as ConnectMethodResponseSuccessItem;


                            }else if(requestItem.method === "personal_sign"){
                                resultItem = {
                                    method: requestItem.method,
                                    chainId: requestItem.chainId,
                                    result: resultItem
                                } as ConnectMethodResponseSuccessItem;

                            }else if(requestItem.method === "btc_signMessage"){

                                resultItem.chainId = requestItem.chainId

                            }else if(requestItem.method === "solana_signMessage"){
                                let sol_successData = resultItem.result as string
                                resultItem.chainId = requestItem.chainId
                                resultItem.result = {
                                    ...callBackParams,
                                    signature:bs58.decode(sol_successData),
                                }
                            }else if (requestItem.method === "sui_signMessage" || requestItem.method === "sui_signPersonalMessage" ){
                                const sui_sign = resultItem.result as string;
                                resultItem.chainId = requestItem.chainId

                                resultItem.result = {
                                    ...callBackParams,
                                    signature:sui_sign
                                }

                            }else if (requestItem.method === "aptos_signMessage"){
                                let aptos_sign = resultItem.result as string
                                if (aptos_sign.startsWith("0x")) {
                                    aptos_sign = aptos_sign.slice(2, aptos_sign.length)
                                }
                                resultItem.chainId = requestItem.chainId
                                if ('injectResult' in resultItem){
                                    resultItem.result = resultItem.injectResult
                                }else {
                                    resultItem.result = {
                                        ...callBackParams,
                                        signature:aptos_sign
                                    }
                                }


                            }else if (requestItem.method === "cosmos_signArbitrary"){
                                if (typeof resultItem.result == "string") {
                                    resultItem.chainId = requestItem.chainId
                                    resultItem.result = JSON.parse(resultItem.result as string)
                                }
                            }
                            list.push(resultItem)
                            logDebug(`OKXUniversalConnectUI checkConnectMethods success result ====> ${JSON.stringify(list)}`);
                            this.universalProvider.events.emit("connect_signResponse", list);
                        }).catch(error =>{
                            let errorItem = creatNotSupportMethodItemReplay(requestItem)
                            list.push(errorItem)
                            logDebug(`OKXUniversalConnectUI checkConnectMethods error ====> ${JSON.stringify(error)}`);
                            this.universalProvider.events.emit("connect_signResponse", list);
                        })


                    }catch (err){
                        let errorItem = creatNotSupportMethodItemReplay(requestItem)
                        list.push(errorItem)
                        logDebug(`OKXUniversalConnectUI checkConnectMethods error ====> ${JSON.stringify(err)}`);
                        this.universalProvider.events.emit("connect_signResponse", list);
                    }

                }else {
                    console.log("checkConnectMethods requestItem is nil",JSON.stringify(connectParams))
                }
            }
        }
    }

    public async openModalAndSign(opts: EngineTypes.ConnectParams, signRequest:RequestParams[]): Promise<SessionTypes.Struct | undefined> {
        logDebug('OKXUniversalConnectUI openModalAndSign() called')
        const updatedOpts = { ...opts, signRequest };
        logDebug('OKXUniversalConnectUI openModalAndSign() opts:',JSON.stringify(opts))
        reportEvent(OKXReportType.CLICK, Report_ConnectDapp_ConnectAndSign_Click, this.universalProvider.providerOpts.dappMetaData.name);
        return  await this.openModal(updatedOpts);
    }

    public async openModal(opts: EngineTypes.ConnectParams): Promise<SessionTypes.Struct | undefined> {
        logDebug('OKXUniversalConnectUI openModal() called')
        try {
            if (this.connected()){
                return this.session
            }
            let signRequest = opts.signRequest ? JSON.parse(JSON.stringify(opts.signRequest)) as RequestParams[] : []
            logDebug("OKXUniversalConnectUI openModal signRequest:",JSON.stringify(signRequest))
            var sessionInfo = await this.singleWalletModal.open("okxAppWallet",opts);
            this.checkConnectMethods(signRequest,sessionInfo?.signResponse)
            return sessionInfo;
        }catch (error) {
            throw error;
        }
    }

    closeModal(reason?: WalletsModalCloseReason): void {
        this.singleWalletModal.close(reason);
    }


    openWallet(connectionRequest?: EngineConnectInfo, connector?: Iconnector) {
        this.universalProvider.openWallet(connectionRequest,connector)
    }

    private onSingleWalletModalStateChange(
      onChange: (state: SingleWalletModalState) => void
    ): () => void {
      console.log("ui - onSingleWalletModalStateChange", onChange);
      return this.singleWalletModal.onStateChange(onChange);
    }

    /**
     * Subscribe to the modal window state changes, returns a function which has to be called to unsubscribe.
     */
    public onModalStateChange(
      onChange: (state: WalletsModalState) => void
    ): () => void {
      console.log("ui - onModalStateChange", onChange);
      return this.onSingleWalletModalStateChange(onChange);
    }

    showActionButton(): boolean {
        return showActionButton(this.getConnectWallet())
    }

    getUniversalProvider(): OKXUniversalProvider {
        return this.universalProvider;
    }

    private getConnectWallet(): WalletInfo | undefined {
        logDebug(`this.session?.wallet?.appName ${this.universalProvider.session?.wallet?.appName}`)
        return this.getWallets().find(wallet => eqWalletName(wallet,this.universalProvider.session?.wallet?.appName))
    }


    public get session():SessionTypes.Struct | undefined{
        return this.universalProvider.session
    }

    public setDefaultChain(chain: string, rpcUrl?: string | undefined) {
        this.universalProvider.setDefaultChain(chain,rpcUrl)
    }

    public on(event: any, listener: any): void {
        this.universalProvider.on(event, listener);
    }

    public once(event: string, listener: any): void {
        this.universalProvider.once(event, listener);
    }

    public removeListener(event: string, listener: any): void {
        this.universalProvider.removeListener(event, listener);
    }

    public off(event: string, listener: any): void {
        this.universalProvider.off(event, listener);
    }

    public get walletName(): string | undefined {
        logDebug(`this.getConnectWallet()?.walletName ${this.getConnectWallet()?.name}`)
        return this.getConnectWallet()?.name;
    }

    disconnect(): Promise<void> {
        return this.universalProvider.disconnect()
    }

    public connected() {
        return this.universalProvider.connected()
    }

    public requestAccountsWithNamespace(namespace: string): string[] {
        return this.universalProvider.requestAccountsWithNamespace(namespace);
    }

    public requestDefaultChainWithNamespace(namespace: string): string {
        return this.universalProvider.requestDefaultChainWithNamespace(namespace)
    }

}


