import {Component, For} from "solid-js";
import {ButtonsContainerStyled, ContainedPrimaryButton, ContainedPrimaryButtonText, TextStyled} from "./style";
import {OKXIconWithLogo} from "../../../../../components/icons/okx-loader-icon";
import {Iconnector, OKX_APP_WALLET, OKX_INJECT_WALLET, OKX_TG_WALLET} from "@okxconnect/core";
import OKXWalletApp from "../../../../../assets/okx-icon-corner.png";
import OKXWalletExtension from "../../../../../assets/okx-wallet-extension.png";
import OKXWalletTg from "../../../../../assets/okx-wallet-tg.png";
import {ImageOKX} from "../../layout/style";

interface ConnectAppTgPageProps {
    connector: Iconnector[],
    walletConnectClick: (connector: Iconnector) => void
}

const MobileConnectAppTgModalPage: Component<ConnectAppTgPageProps> = (props) => {


    const okxwalletIcon = (connector: Iconnector) => {
        switch (connector.getTag()) {
            case OKX_APP_WALLET:
                return <ImageOKX src={OKXWalletApp}/>
            case OKX_INJECT_WALLET:
                return <ImageOKX src={OKXWalletExtension}/>
            case OKX_TG_WALLET:
                return <ImageOKX src={OKXWalletTg}/>
            default:
                return <ImageOKX src={OKXWalletApp}/>
        }
    }

    return (
        <>
            <ButtonsContainerStyled>
                <OKXIconWithLogo/>
                <TextStyled translationKey="walletModal.mobileConnectionModal.appAndTelegram.text">
                    OKX Wallet supports OKX app and Telegram Mini Apps
                </TextStyled>
            </ButtonsContainerStyled>

            <ButtonsContainerStyled>
                <For each={props.connector}>
                    {(connector) => (
                        <ContainedPrimaryButton
                            leftIcon={okxwalletIcon(connector)}
                            onClick={() => props.walletConnectClick(connector)}>
                            <ContainedPrimaryButtonText translationKey={connector.walletNameKey}>
                                {connector.walletName}
                            </ContainedPrimaryButtonText>
                        </ContainedPrimaryButton>
                    )}
                </For>
            </ButtonsContainerStyled>
        </>
    );
};

export default MobileConnectAppTgModalPage;
