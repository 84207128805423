import {
  encodeConnectParams,
  EngineConnectInfo,
  getAppWalletDeepLink, getAppWalletShortDeepLink,
  getOKXLink,
  getUniversalLink,
  Iconnector,
  logDebug,
  OKX_INJECT_WALLET,
  OKX_TG_WALLET,
  OKXReportType,
  Report_ChooseType_ConnectWallet_Click,
  Report_ChooseType_ConnectWallet_View,
  Report_Loading_ConnectWallet_View,
  Report_Proceed_QRCode_View,
  Report_Proceed_Retry_Click,
  reportEvent,
  useShortLink,
  WalletInfo
} from "@okxconnect/core";
import {Component, createEffect, createMemo, createSignal, JSX, onMount, Show, useContext,} from "solid-js";
import {BodyStyled, MobileConnectionModalStyled} from "./style";
import {useTheme} from "solid-styled-components";
import LoadingModalPage from "../layout/LoadingModalPage";
import {H1Styled} from "../style";
import ConnectionDeclinedModalPage from "../layout/ConnectionDeclinedModalPage";
import ModalFooter from "../layout/ModalFooter";
import {OKXConnectUiContext} from "../../../../../config/okx-connect-ui.context";
import {WalletInfoRemoteWithOpenMethod, WalletInfoWithOpenMethod, WalletOpenMethod} from "../../../../../models";
import DesktopConnectAppTgModalPage from "./desktop-wallet-select-modal";


export interface DesktopConnectionProps {
  wallet: WalletInfo;
  onBackClick: () => void;
  backDisabled?: boolean;
  connect: () => Promise<unknown>;
  universalLinkParams: () => EngineConnectInfo | null;
  showRetry:boolean;
  connectError:() => boolean
  sessionConnecting:() => boolean
  walletConnector:Iconnector[]
  lastSelectedWalletInfo: WalletInfoRemoteWithOpenMethod | {
    openMethod: WalletOpenMethod;
  } | null;
  setLastSelectedWalletInfo(walletInfo: WalletInfoWithOpenMethod | {
    openMethod: WalletOpenMethod;
} | null): void;
}

export const DesktopConnectionModal: Component<DesktopConnectionProps> = (
  props
) => {
  const theme = useTheme();

  const okxConnectUI = useContext(OKXConnectUiContext)

  const [walletType, setWalletType] = createSignal<"app" | "mini" | null>(null);
  const showAppAndTgState = createMemo(
    () =>  walletType() === null
  );
  const [injectedOpen,setInjectedOpen] = createSignal(false)

  var lastActionBtnShowState: boolean | undefined;
  var lastQRViewShowState: boolean | undefined;
  let showLoading = () => props.universalLinkParams() === null && !props.connectError();
  var lastLoadingState: boolean | undefined;

  props.setLastSelectedWalletInfo({
    ...props.wallet,
    openMethod: "qrcode",
  });

  const showLoadModalPage = createMemo(()=> props.universalLinkParams() === null && !props.connectError())

  const showNotLoadingPage = createMemo(()=>props.universalLinkParams() !== null || props.connectError())

  const showErrorPage = createMemo(()=> showNotLoadingPage() && props.connectError())

  const showWalletConnectPage = createMemo(() => showNotLoadingPage() && !props.connectError())

  createEffect(() => {
    logDebug("TON customEvent.detail  :::",createEffect)
    var info = props.universalLinkParams();
    logDebug("TON customEvent.detail  :::",JSON.stringify(info))
    const showState = showAppAndTgState();
    const showLoadingState = showLoading();
    if (showState != lastActionBtnShowState && !showLoadingState) {
      if (showState) {
        // report show
        reportEvent(OKXReportType.PAGE_IN, Report_ChooseType_ConnectWallet_View, '')
        reportEvent(OKXReportType.PAGE_OUT, Report_Proceed_QRCode_View, '')
      } else {
        // report hidden
        reportEvent(OKXReportType.PAGE_OUT, Report_ChooseType_ConnectWallet_View, '')
        reportEvent(OKXReportType.PAGE_IN, Report_Proceed_QRCode_View, '')
      }
      lastActionBtnShowState = showState;
    }

    if (showLoadingState != lastLoadingState) {
      if (showLoadingState) {
        // report show
        reportEvent(OKXReportType.PAGE_IN, Report_Loading_ConnectWallet_View, '')
      } else {
        // report hidden
        reportEvent(OKXReportType.PAGE_OUT, Report_Loading_ConnectWallet_View, '')
      }
      lastLoadingState = showLoadingState;
    }
    if (info) {
      props.setLastSelectedWalletInfo({
        ...props.wallet,
        openMethod: "universal-link",
      });

      var connectRequest = info.connectRequest

      if (connectRequest) {
        var qrLink = getAppWalletShortDeepLink(connectRequest.topic, connectRequest.clientId)
        setQrLink(qrLink);
      }

      var okxInjectConnector = props.walletConnector.find((connector) => connector.getTag() === OKX_INJECT_WALLET)
      if (okxInjectConnector){
        okxInjectConnector.openWallet(info)
        setInjectedOpen(true)
        props.setLastSelectedWalletInfo({
          ...props.wallet,
          openMethod: "inject",
        });
      }
    }

  });

  const [qrLink, setQrLink] = createSignal<string | null>(null);

  onMount(async () => {
    try {
       await props.connect().catch((error) => 
        logDebug("DesktopConnectionModal connect catch error ",JSON.stringify(error))
      );
    } catch (error) {
      logDebug("DesktopConnectionModal connect  error ",JSON.stringify(error));
    }
  });

  const onRetry = (): void => {
    setWalletType(null);

    reportEvent(OKXReportType.CLICK, Report_Proceed_Retry_Click, '');
    try {
      props.connect();
    } catch (error) {
      console.error("Error while connecting:", error);
    }
  };

  const injectedRetry = ():void=>{
    var injectedConnector = props.walletConnector.find((connecotr)=>{
      return connecotr.getTag() === OKX_INJECT_WALLET
    })
    if (injectedConnector){
      injectedWalletClick(injectedConnector)
    }
  }

  const injectedWalletClick = (connector: Iconnector): void => {
    var info = props.universalLinkParams();
    if (info) {
      connector.openWallet(info)
      props.setLastSelectedWalletInfo({
        ...props.wallet,
        openMethod: "inject",
      });
    }
  }


  const walletConnectClick = (connector:Iconnector)=>{
    var info = props.universalLinkParams();
    switch (connector.getTag()){
      case OKX_TG_WALLET:{
        logDebug('desktop on connect mini')
        reportEvent(OKXReportType.CLICK, Report_ChooseType_ConnectWallet_Click, btoa(JSON.stringify({Report_WalletType_Key: 'tgminiwallet'})));
        okxConnectUI?.closeModal()
        if (info){
          connector.openWallet(info)
        }
        props.setLastSelectedWalletInfo({
          ...props.wallet,
          openMethod: "universal-link",
        });
        break
      }
    }
  }



  return (
    <MobileConnectionModalStyled data-tc-wallets-modal-connection-mobile="true">

      <H1Styled>OKX Connect</H1Styled>

      <Show<boolean, () => JSX.Element> when={showLoadModalPage()}>
        <LoadingModalPage />
        <ModalFooter wallet={props.wallet} />
      </Show>

      <Show<boolean, () => JSX.Element> when={showNotLoadingPage()}>
        <Show<boolean, () => JSX.Element> when={showErrorPage()}>
          <BodyStyled>
            <ConnectionDeclinedModalPage
                onRetry={onRetry}
                showRetry={props.showRetry}
            />
          </BodyStyled>
        </Show>

        <Show<boolean, () => JSX.Element> when={showWalletConnectPage()}>
            <BodyStyled>
              <DesktopConnectAppTgModalPage
                connector={props.walletConnector}
                wallet={props.wallet}
                sessionConnecting={props.sessionConnecting}
                qrLink={():string=>{
                  return qrLink()!!
                }}
                injectedRetry={injectedRetry}
                injectedOpen={injectedOpen}
              />
            </BodyStyled>
        </Show>

        <ModalFooter wallet={props.wallet} />
      </Show>
    </MobileConnectionModalStyled>
  );
};
