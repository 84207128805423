import {
    ConnectNamespaceMap,
    creatOKXWalletInfo,
    getTelegramWalletTWAUrl,
    logDebug,
    NameSpaceKeyAptos,
    NameSpaceKeyBtc,
    NameSpaceKeyEip155,
    NameSpaceKeySOL,
    NameSpaceKeySui,
    NameSpaceKeyTON,
    openOKXDeeplink,
    NameSpaceKeyTron,
    NameSpaceKeyStarknet,
    NameSpaceKeyCosmos,
    openOKXDeeplinkWithFallback,
    openOKXTMAWalletlinkWithFallback,
    WalletInfo,
    Iconnector,
    AppwalletConnector,
    TgwalletConnector,
    OkxwalletInjectConnector,
    standardDeeplink,
    OKX_TG_WALLET,
    OKX_APP_WALLET,
    OKX_INJECT_WALLET,
    logWarning,
    SOLANA_CHAINS,
    versionCompare,
    ConnectNamespace,
    RequestMetadata,
    creatOKXMiniAppWalletInfo,
    APTOS_CHAINS,
    OkxwalletInjectTonsdkConnector
} from "@okxconnect/core";
import {UIWallet, WalletOpenMethod, WalletsListConfiguration} from '../../models';
import {mergeConcat} from './array';
import {isInTelegramBrowser, isInTMA} from "./tma-api";
import {isMobile} from "../hooks/isMobile";
import {creatOKXInjectWalletInfo} from "@okxconnect/core";
import {universalAppState} from "../../universal/config/universal-app.state";
import {tonAppState} from "../../ton/config/ton-app.state";

export function uiWalletToWalletInfo(uiWallet: UIWallet): WalletInfo {
    // if ('jsBridgeKey' in uiWallet) {
    //     return {
    //         ...uiWallet
    //     };
    // }

    return uiWallet;
}

export function applyWalletsListConfiguration(
    walletsList: WalletInfo[],
    configuration?: WalletsListConfiguration
): WalletInfo[] {
    if (!configuration) {
        return walletsList;
    }

    if (configuration.includeWallets?.length) {
        walletsList = mergeConcat(
            'name',
            walletsList,
            configuration.includeWallets.map(uiWalletToWalletInfo)
        );
    }

    return walletsList;
}

// export function supportsDesktop(walletInfo: WalletInfo): boolean {
//     return walletInfo.platforms.some(w => ['macos', 'linux', 'windows'].includes(w));
// }
//
// export function supportsMobile(walletInfo: WalletInfo): boolean {
//     return walletInfo.platforms.some(w => ['ios', 'android'].includes(w));
// }
//
// export function supportsExtension(walletInfo: WalletInfo): boolean {
//     return walletInfo.platforms.some(w => ['chrome', 'firefox', 'safari'].includes(w));
// }

export function eqWalletName(wallet1: { name: string; appName: string }, name?: string): boolean {
    if (!name) {
        return false;
    }

    return (
        wallet1.name.toLowerCase() === name.toLowerCase() ||
        wallet1.appName.toLowerCase() === name.toLowerCase()
    );
}


export function inTg(): boolean {
    // return true
    return isInTelegramBrowser() || isInTMA()
}

export function showTonTgWallet() {
    // return false
    if (!inTg()) {
        return false
    }
    return true
}
const showTgNameSpace = [NameSpaceKeySui,NameSpaceKeySOL,NameSpaceKeyEip155,NameSpaceKeyTON,
        NameSpaceKeyBtc,NameSpaceKeyAptos, NameSpaceKeyTron, NameSpaceKeyStarknet, NameSpaceKeyCosmos]

const showInjectedNameSpace = [
    NameSpaceKeyEip155,NameSpaceKeySOL,NameSpaceKeyTON,NameSpaceKeySui,NameSpaceKeyAptos,NameSpaceKeyBtc
]

const limitInjectedNameSpace = [
    NameSpaceKeySOL,
    NameSpaceKeyAptos
]

const showPCInjectChain:string[] = [
    SOLANA_CHAINS.MAINNET,
    APTOS_CHAINS.MAINNET,
    APTOS_CHAINS.MOVEMENT_MAINNET,
    APTOS_CHAINS.MOVEMENT_TESTNET,
]

const showMobileInjectChain:string[] = [
    SOLANA_CHAINS.MAINNET,
    APTOS_CHAINS.MAINNET,
]

const showInjectChain:string[] = isMobile() ? showMobileInjectChain : showPCInjectChain


export function showUniversalTgWalletByRequestNameSpaces(nameSpaces?: ConnectNamespaceMap, optionalNameSpace?: ConnectNamespaceMap) {
    if (!showUniversalTgWallet()) {
        return false
    }
    return EqnameSpace(nameSpaces) && EqnameSpace(optionalNameSpace)
}

export function injectWalletEnable(nameSpaces?: ConnectNamespaceMap,dappMetaData?:RequestMetadata):boolean{
    if (!nameSpaces){
        return true
    }

    for (const [namespace, connectNamespace] of Object.entries(nameSpaces)) {
        if (limitInjectedNameSpace.includes(namespace)) {
            const hasHiddenChain = connectNamespace.chains.some((chain) =>
                !showInjectChain.includes(chain)
            );
            if (hasHiddenChain) {
                return false;
            }
        } else if (!showInjectedNameSpace.includes(namespace)) {
            return false;
        }

        const nameSpaceEnable = checkNameSpaceEnable(namespace,dappMetaData)
        if (!nameSpaceEnable){
            return false
        }
    }
    return true
}


function checkNameSpaceEnable(nameSpace: string, dappMetaData?: RequestMetadata): boolean {
    switch (nameSpace) {
        case NameSpaceKeyTON:
            if (!dappMetaData || !dappMetaData.manifestUrl) {
                return false
            }
    }
    if (isMobile()) {
        return appVersionAtLeast('6.103.0')
    }
    return true
}


export function checkIconnectorEnable(universal:boolean = true){
    const connectors = universal?universalAppState.walletConnector:tonAppState.walletConnector
    if (!connectors){
        logWarning("not find connector yet")
        return
    }
    for (let iconnector of connectors) {
        switch (iconnector.getTag()) {
            case OKX_TG_WALLET:
                if (universal) {
                    var nameSpaces = universalAppState.connectRequestParameters?.namespaces
                    var optionalNameSpace = universalAppState.connectRequestParameters?.optionalNamespaces
                    iconnector.enable = showUniversalTgWalletByRequestNameSpaces(nameSpaces, optionalNameSpace)
                } else {
                    iconnector.enable = true
                }
                break
            case OKX_APP_WALLET:
                iconnector.enable = true
                break
            case OKX_INJECT_WALLET:
                if (universal) {
                    var nameSpaces = universalAppState.connectRequestParameters?.namespaces
                    var optionalNameSpace = universalAppState.connectRequestParameters?.optionalNamespaces
                    iconnector.enable = injectWalletEnable(nameSpaces,universalAppState.dappMetaData) && injectWalletEnable(optionalNameSpace,universalAppState.dappMetaData)
                } else {
                    iconnector.enable = true
                    var ton = tonAppState.dappMetaData
                    iconnector.enable = !!ton?.manifestUrl
                }
                break
        }
    }
}


export function showUniversalTgWalletBySessionNameSpaces(sessionNameSpace?: ConnectNamespaceMap) {
    if (!showUniversalTgWallet()) {
        return false
    }
    return EqnameSpace(sessionNameSpace)
}

function EqnameSpace(nameSpaceMap?: ConnectNamespaceMap): boolean {
    // return true
    try {
        if (!nameSpaceMap) return true
        const keys = Object.keys(nameSpaceMap);
        return keys.every(element => showTgNameSpace.includes(element));
    } catch (e) {
        return false
    }
}

export function defaultOpenUniversalLink(nameSpaces?: ConnectNamespaceMap, optionalNameSpace?: ConnectNamespaceMap): boolean {
    if (showInjectWallet()){
        return false
    }

    if (!showUniversalTgWalletByRequestNameSpaces(nameSpaces, optionalNameSpace)) {
        return isMobile()
    }
    return false
}

export function defaultOpenTonUniversalLink(): boolean {
    if (showInjectWallet()){
        return false
    }

    if (!showTonTgWallet()) {
        return isMobile()
    }
    return false
}


export function isAppWallet(walletInfo?: { name: string } | undefined | null): boolean {
    if (!walletInfo) return false
    return walletInfo.name === creatOKXWalletInfo().name
}

export function isTgWallet(walletInfo?: { name: string } | undefined | null): boolean {
    if (!walletInfo) return false
    return walletInfo.name === creatOKXMiniAppWalletInfo().name
}


export function openUniversalWallet(walletInfo: WalletInfo | undefined | null, twaReturnUrl: string | undefined, sessionNameSpace?: ConnectNamespaceMap) {
    logDebug("wallets openUniversalWallet")
    if (showUniversalTgWalletBySessionNameSpaces(sessionNameSpace)) {
        openWallet(walletInfo, twaReturnUrl)
    }
}

export function openTonWallet(walletInfo: WalletInfo | undefined | null, twaReturnUrl: string | undefined) {
    logDebug("wallets openTonWallet")
    if (showTonTgWallet()) {
        openWallet(walletInfo, twaReturnUrl)
    }
}

export function openWallet(walletInfo: WalletInfo | undefined | null, twaReturnUrl: string | undefined) {
    // 当展示tg wallet 时候，openUniversalLink = false,需要在ui 中执行跳转；
    logDebug("wallets openWallet")
    if (walletInfo) {
        if (isAppWallet(walletInfo)) {
            logDebug(`wallets openWallet openOKXDeeplinkWithFallback`)
            openOKXDeeplink(standardDeeplink)
        } else {
            logDebug(`wallets openWallet openOKXTMAWalletlinkWithFallback`)
            openOKXTMAWalletlinkWithFallback(getTelegramWalletTWAUrl(undefined, twaReturnUrl))
        }
    }
}


export function browserDebug() {
    return !(isInTelegramBrowser() || isInTMA())
}

export function openWalletForUIRequest(walletInfo: WalletInfo | undefined | null, openMethod: WalletOpenMethod | undefined) {
    if (openMethod === 'qrcode'){
        return false
    }

    if (isInjectWallet(walletInfo)){
        return false
    }

    return true
}

export function isInjectWallet(walletInfo: WalletInfo | undefined | null) {
    if (!walletInfo) return false
    return walletInfo.name === creatOKXInjectWalletInfo().name
}

export function showInjectWallet() {
    try {
        if (isMobile()) {
            return appVersionAtLeast('6.102.0')
        } else {
            return !!((window as any).okxwallet)
        }
    } catch (e) {
        return false
    }
}

function appVersionAtLeast(ver:string){
    let okxWallet = (window as any).okxwallet
    if (okxWallet) {
        let version = okxWallet.appVersion
        return versionCompare(version, ver) >= 0
    } else {
        return false
    }
}

export function isMobileDiscover() {
    return isMobile() && !!((window as any).okxwallet)
}

export function getDefaultConnector(): Iconnector[] {
    let result: Iconnector[] = []
    if (!isMobileDiscover()) {
        result.push(new AppwalletConnector())
    }

    if (showUniversalTgWallet() || !isMobile()) {
        result.push(new TgwalletConnector())
    }

    if (showInjectWallet()) {
        result.push(new OkxwalletInjectConnector())
    }
    return result
}

function showUniversalTgWallet(): boolean {
    if (inTg()) {
        return true
    }
    return false
}

export function getDefaultTonConnector(): Iconnector[] {
    let result: Iconnector[] = []
    if (!isMobileDiscover()) {
        result.push(new AppwalletConnector())
    }

    if (inTg()) {
        result.push(new TgwalletConnector())
    }

    if (showInjectWallet()) {
        result.push(new OkxwalletInjectTonsdkConnector)
    }
    return result
}

export function showActionButton(walletInfo?: { name: string } | undefined | null): boolean {
    if (!walletInfo) {
        return false
    }

    if (isAppWallet(walletInfo)) {
        return isMobile()
    }

    if (isTgWallet(walletInfo)) {
        return inTg()
    }

    return false
}
