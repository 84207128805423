import {styled} from "solid-styled-components";
import {Text,} from "../../../../../components";
import {ContainedButton, H1StyledMaxWidth} from "../../layout/style";

export const ButtonsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 12px;
`;

export const TextStyled = styled(Text)`
  max-width: 262px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  color: ${(props) => props.theme!.colors.text.contrast};
`;


export const ContainedPrimaryButton = styled(ContainedButton)`
  background-color: ${(props) => props.theme!.colors.text.primary};
  margin-left: 24px;
  width: calc(100% - 48px);
  margin-right: 24px;
`;

export const ContainedPrimaryButtonText = styled(H1StyledMaxWidth)`
  font-size: 14px;
  color: ${(props) => props.theme!.colors.background.primary};
`;

