import { Component, createContext, createEffect, Match, Switch } from 'solid-js';
import { Modal } from '../../../components';
import { ConfirmTransactionModal } from './confirm-transaction-modal';
import { TransactionCanceledModal } from './transaction-canceled-modal';
import { TransactionSentModal } from './transaction-sent-modal';
import { Action } from '../../../../app/models/action-modal';
import {TransactionErrorModal} from "./transaction-error-modal";

interface ActionsModalProps {
    action: Action | null;
    setAction: (prev: Action | null) => void;
}

export const ActionsModalContext = createContext<{ action: Action | null }>({
    action: null
});

export const ActionsModal: Component<ActionsModalProps> = (props) => {
    createEffect(() => {
        console.log('ActionsModal', props.action);
    })
    return (
      <ActionsModalContext.Provider value={{ action: props.action }}>
        <Modal
          opened={props.action !== null && props.action?.openModal === true}
          onClose={() => props.setAction(null)}
          data-tc-actions-modal-container="true"
        >
          <Switch>
            <Match when={props.action!.name === "transaction-sent"}>
              <TransactionSentModal onClose={() => props.setAction(null)} />
            </Match>
            <Match when={props.action!.name === "transaction-canceled"}>
              <TransactionCanceledModal onClose={() => props.setAction(null)} />
            </Match>
            <Match when={props.action!.name === "confirm-transaction"}>
              <ConfirmTransactionModal onClose={() => props.setAction(null)} />
            </Match>
              <Match when={props.action!.name === "transaction-error"}>
                  <TransactionErrorModal onClose={() => props.setAction(null)} />
              </Match>
          </Switch>
        </Modal>
      </ActionsModalContext.Provider>
    );
};
